import RedacaoCorrigida from "@/interfaces/RedacaoCorrigida";

const RedacaoCorrigidaColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    sortable: false,
  },
  {
    name: "aluno",
    align: "left",
    label: "Aluno",
    field: (row: RedacaoCorrigida) => row.aluno.nome,
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data de Submissão",
    field: (row: RedacaoCorrigida) => row.created_at,
    sortable: true,
  },
  {
    name: "updated_at",
    align: "center",
    label: "Data de Correção",
    field: (row: RedacaoCorrigida) => (row.status == "C" ? row.updated_at : ""),
    sortable: true,
  },
];

export default RedacaoCorrigidaColumn;
